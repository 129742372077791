<template>
  <div class="escalate">
    <!-- Actions -->
    <div class="section" v-if="!isArchived">
      <h3>Get More Help</h3>
      <div class="actions">
        <!-- Add another responder -->
        <div class="action-button" @click.prevent="openAddStaffMembersModal">
          <div class="left">
            <a-icon type="user-add" />
          </div>
          <div class="right">
            <div class="title">Add another responder</div>
            <div class="description">
              Add an additional responder to this incident
            </div>
          </div>
        </div>
        <!-- / Add another responder -->

        <add-staff-members-modal
          @add-staff-members="addStaffMembers"
          :tenant-id="tenantId"
          :org-id="orgId"
          :visible="addStaffMembersModalVisible"
          :membership="membership"
          @close-modal="closeAddStaffMembersModal"
        ></add-staff-members-modal>

        <!-- Add another response team -->
        <div class="action-button" @click.prevent="openAddResponseTeamModal">
          <div class="left">
            <a-icon type="usergroup-add" />
          </div>
          <div class="right">
            <div class="title">Add another response team</div>
            <div class="description">
              Add an aditional response team to this incident
            </div>
          </div>
        </div>
        <!-- / Add another response team -->

        <add-response-team-modal
          @add-response-team="addResponseTeam"
          :tenant-id="tenantId"
          :org-id="incident.ownerId"
          :visible="addResponseTeamModalVisible"
          :response-teams="responseTeams"
          @close-modal="closeAddResponseTeamModal"
        ></add-response-team-modal>

        <!-- Escalate to parent org -->
        <div
          v-if="parentOrganisation"
          class="action-button"
          @click.prevent="openEscalateToParentOrgModal"
        >
          <div class="left">
            <organisation-thumbnail
              :tenantId="parentOrganisationTenantId"
              :orgId="parentOrganisation.id"
            ></organisation-thumbnail>
          </div>
          <div class="right">
            <div class="title">
              Escalate to {{ parentOrganisation["displayName"] }}
            </div>
            <div class="description">
              Add a response team from {{ parentOrganisation["displayName"] }}
            </div>
          </div>
        </div>

        <escalate-to-parent-org-modal
          @add-response-team="addParentOrgResponseTeam"
          v-if="parentOrganisation"
          :tenant-id="tenantId"
          :org-id="incident.ownerId"
          :parent-org-id="parentOrganisation.id"
          :visible="escalateToParentOrgModalVisible"
          :response-teams="responseTeams"
          @close-modal="closeEscalateToParentOrgModal"
        >
        </escalate-to-parent-org-modal>
        <!-- / Escalate to parent org -->

        <!-- Make an announcement -->
        <div
          class="action-button"
          v-if="canCreateAnnouncement"
          @click.prevent="openCreateAnnouncementModal"
        >
          <div class="left">
            <a-icon type="notification" />
          </div>
          <div class="right">
            <div class="title">Make an announcement</div>
            <div class="description">Send an announcement to all staff</div>
          </div>
        </div>
        <!-- / Make an announcement -->

        <!-- Create announcement modal -->
        <create-announcement-modal
          :initial-title="incident.displayName"
          :visible="createAnnouncementModalVisible"
          @close-modal="closeCreateAnnouncementModal"
        >
        </create-announcement-modal>
        <!-- / Create announcement modal -->
      </div>
    </div>
    <!-- / Actions -->

    <!-- Share with -->
    <div class="section">
      <share-with></share-with>
    </div>
    <!-- / Share with -->

    <!-- Already shared with -->
    <div class="section">
      <shared-with></shared-with>
    </div>
    <!-- / Already shared with -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import incidents from "../../../../api/incidents";
import AddResponseTeamModal from "../../../../components/Escalate/AddResponseTeamModal.vue";
import ShareWith from "./Escalate/ShareWith.vue";
import SharedWith from "./Escalate/SharedWith.vue";
import AddStaffMembersModal from "../../../../components/SendMessageActions/AddStaffMembersModal.vue";
import CreateAnnouncementModal from "../../../../components/Announcements/CreateAnnouncementModal.vue";
import OrganisationThumbnail from "../../../../components/Organisations/OrganisationThumbnail.vue";
import EscalateToParentOrgModal from "../../../../components/Escalate/EscalateToParentOrgModal.vue";
const _ = require("lodash");
export default {
  props: ["myPresenceId", "tenantId", "orgId", "membership", "adminMode"],

  data() {
    return {
      addResponseTeamModalVisible: false,
      isAddingResponseTeam: false,
      addStaffMembersModalVisible: false,
      createAnnouncementModalVisible: false,
      escalateToParentOrgModalVisible: false,
    };
  },

  components: {
    AddResponseTeamModal,
    ShareWith,
    SharedWith,
    AddStaffMembersModal,
    CreateAnnouncementModal,
    OrganisationThumbnail,
    EscalateToParentOrgModal,
  },

  computed: {
    ...mapGetters("incidentViewer", {
      // tenantId: "tenantId",
      incident: "incident",
      responseTeams: "responseTeams",
      // myPresenceId: "myPresenceId",
      isArchived: "isArchived",
    }),

    ...mapGetters("missionControl", {
      organisations: "organisations",
      organisationsICanCreateAnnouncementsFor: "organisationsICanCreateAnnouncementsFor",
      organisationsIAmNotAnAdminFor: "organisationsIAmNotAnAdminFor",
      presences: "presences",
    }),

    ...mapGetters("admin", {
      isMyPresenceHidden: "isMyPresenceHidden"
    }),

    canCreateAnnouncement() {
      if (this.adminMode && this.isMyPresenceHidden) {
        return false;
      }
      return _.map(this.organisationsICanCreateAnnouncementsFor, 'id').includes(this.incident.ownerId)
    },

    organisation() {
      return _.find(this.organisations, {
        id: this.incident.ownerId,
      });
    },

    parentOrganisation() {
      if (this.organisation) {
        return _.find(this.organisations, { id: this.organisation.ownerId });
      }
      return null;
    },

    parentOrganisationTenantId() {
      if (this.parentOrganisation) {
        return this.parentOrganisation.ownerId
          ? this.parentOrganisation.ownerId
          : this.parentOrganisation.id;
      }
      return null;
    },
  },

  methods: {
    ...mapActions("incidentViewer", {
      loadResponseTeams: "loadResponseTeams",
    }),

    addParentOrgResponseTeam(team) {
      console.log("add parent org response team");
      console.log(team);
      let vm = this;
      vm.isAddingResponseTeam = true;

      // TODO: DETERMINE REAL TIMESTAMP
      incidents
        .addResponseTeamToIncident(
          this.tenantId,
          this.incident.id,
          team.id,
          this.parentOrganisation.id,
          this.myPresenceId,
          "2021-11-26T13:22:12.0345404Z Europe/London"
        )
        .then(() => {
          vm.isAddingResponseTeam = false;
          vm.$message.success("Response team added successfully");
          vm.$emit("response-team-added");
          vm.loadResponseTeams();
        })
        .catch((e) => {
          console.log(e);
          vm.isAddingResponseTeam = false;
          vm.$message.error("Error adding response team");
        });
    },

    addResponseTeam(team) {
      console.log("add response team");
      console.log(team);
      let vm = this;
      vm.isAddingResponseTeam = true;

      // TODO: DETERMINE REAL TIMESTAMP
      incidents
        .addResponseTeamToIncident(
          this.tenantId,
          this.incident.id,
          team.id,
          this.incident.ownerId,
          this.myPresenceId,
          "2021-11-26T13:22:12.0345404Z Europe/London"
        )
        .then(() => {
          vm.isAddingResponseTeam = false;
          vm.$message.success("Response team added successfully");
          vm.$emit("response-team-added");
          vm.loadResponseTeams();
        })
        .catch((e) => {
          console.log(e);
          vm.isAddingResponseTeam = false;
          vm.$message.error("Error adding response team");
        });
    },

    openAddResponseTeamModal() {
      this.addResponseTeamModalVisible = true;
    },

    closeAddResponseTeamModal() {
      this.addResponseTeamModalVisible = false;
    },

    openAddStaffMembersModal() {
      this.addStaffMembersModalVisible = true;
    },

    closeAddStaffMembersModal() {
      this.addStaffMembersModalVisible = false;
    },

    addStaffMembers(memberIds) {
      this.$emit("add-staff-members", memberIds);
    },

    openCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = true;
    },

    closeCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = false;
    },

    openEscalateToParentOrgModal() {
      this.escalateToParentOrgModalVisible = true;
    },

    closeEscalateToParentOrgModal() {
      this.escalateToParentOrgModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.escalate {
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  // No scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.actions {
  margin-top: 15px;
}

.action-button {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #fff;
  margin-bottom: 15px;

  display: flex;
  .left {
    flex-shrink: 1;
    min-width: 50px;

    .anticon {
      font-size: 25px;
      color: #5155ea;
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    flex-grow: 1;
    padding-left: 15px;
  }

  .description {
    font-weight: 400;
    margin-top: 3px;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}

.section {
  margin-bottom: 30px;
}
</style>